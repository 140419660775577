var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("job-card", {
        attrs: {
          "job-post": _vm.jobPost,
          "save-visible": false,
          "apply-visible": false,
          "meta-visible": false,
          "details-visible": true
        }
      }),
      _vm.jobPost && !_vm.currentLogin
        ? _c(
            "v-card",
            {
              staticClass: "mb-3 primary--text ma-4",
              attrs: { color: "white xaccent", darkx: "" }
            },
            [
              _c("v-card-title", { staticClass: "fun-font title" }, [
                _vm._v("Want to apply?")
              ]),
              _c(
                "v-card-text",
                [
                  _c("p", [
                    _vm._v(
                      "This is your chance to stand out. Sign up, records a 1-minute video pitch and apply."
                    )
                  ]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.jobPost.organization.name))
                    ]),
                    _vm._v(
                      "\n          and other organizations will be able to watch your pitch, and contact you.\n        "
                    )
                  ]),
                  false
                    ? _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "font-size": "100px",
                            opacity: "0.2",
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            padding: "20px"
                          }
                        },
                        [_vm._v("warning")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass: "text-xs-center",
                    staticStyle: { display: "block", flex: "1" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          large: "",
                          round: "",
                          color: "primary",
                          to: { name: "Accounts_Create" }
                        }
                      },
                      [_vm._v("Sign Up")]
                    )
                  ],
                  1
                )
              ]),
              _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass: "text-xs-center",
                    staticStyle: { display: "block", flex: "1" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          round: "",
                          color: "primary",
                          flat: "",
                          to: { name: "Login" }
                        }
                      },
                      [_vm._v("Sign In")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }