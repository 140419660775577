



















































import Vue from "vue";
import * as models from "@/models";
import { G /*, rules */ } from "@/globals";
import jobCard from "@/components/jobs/jobCard.vue";
import { lookups } from "@/code/api.lookups";

// import { accounts } from "@/code/api.accounts";
// import { organizations } from "@/code/api.organizations";
// import countryCitySelector from "@/components/country-city-selector.vue";

declare type viewMode = "create" | "edit";

export default {
  name: "Public_JobPost",
  components: {
    jobCard
  },
  props: ["passedJobPost"],
  data: () => ({
    isSaving: false,
    jobPost: <models.jobPostExtended>null,
    jobDetailsVisible: true,
    initializing: true
    // rules: {
    //   required: rules.required,
    //   max100: rules.maxCounter(100),
    //   max280: rules.maxCounter(280),
    //   max: rules.maxCounter
    // }
  }),
  watch: {
    jobPost: function(newValue: models.jobPost) {
      if (!newValue) return;
      G.data.customTitle = newValue.title;
    }
  },
  computed: {
    // seekers: function(): models.seekerProfile[] {
    //   let jp: models.jobPostExtended = this.jobPost;
    //   if (
    //     !jp ||
    //     !jp.context ||
    //     !jp.context.recruiter ||
    //     !jp.context.recruiter.seekerLabels ||
    //     jp.context.recruiter.seekerLabels.length == 0
    //   )
    //     return [];
    //   return jp.context.recruiter.seekerLabels.map(sl => sl.seekerProfile);
    // },
  },
  created: function() {
    G.data.customTitle = "Job Post";
  },
  mounted: function() {
    try {
      G.showLoading();
      if (this.passedJobPost) {
        //PostJob Post object was passed
        this.jobPost = this.passedJobPost;
      } else {
        // No object passed
        let paramId = this.$route.params.id;
        // console.log(qsId);

        if (paramId && !isNaN(parseInt(paramId))) {
          //JP Id was passed
          let jpId: number;
          //TODO: nmew stuff
          // view OR edit
          jpId = parseInt(paramId);
          lookups.getJobPostById(jpId).then(jpx => (this.jobPost = jpx));
        } else {
          // no post avialvle, go to org home
          this.$router.push({ name: "Home" });
        }
      }
    } finally {
      G.hideLoading();
    }
  },
  methods: {}
};
